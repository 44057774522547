import axios from "axios";
import DOMPurify from 'dompurify';
import { firestore } from "../firebase";

const API_HOST = process.env.VUE_APP_APIHOST;
const MSG_API_HOST = process.env.VUE_APP_MSG_API_HOST;

let getOrderById = (orderId) => {
  return axios
    .get(`${API_HOST}/pub/orders/${orderId}/cart?includeOrderData=true`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(`Cannot get order ${orderId} data`, err);
    });
};

let acceptOrder = (orderId) => {
  return axios
    .get(`${API_HOST}/pub/qcpos/orders/${orderId}/accept`)
    .then((response) => {
      firestore_update_status();
    })
    .catch((err) => {
      console.log(`Cannot get order ${orderId} data`, err);
    });
};

let firestore_update_status = (shopId, orderId, status) => {
  firestore
    .doc(`shops/${shopId}/orders/${orderId}`)
    .set({ status: status }, { merge: true })
    .then(() => {})
    .catch((err) => {
      console.log("Firestore doc update failed", err);
    });
};

export const createQRCodeHtmlContent = (orderId) => {
  return axios
    .get(`${MSG_API_HOST}/custom/orders/${orderId}`)
    .then((response) => {
      return DOMPurify.sanitize(response.data);
    })
    .catch((err) => {
      console.error("createQRCodeHtmlContent", err);
    });
};

export default {
  getOrderById,
};
<template>
  <div>
    <!-- Numpad header -->
    <v-row class="pa-2">
      <v-col cols="12">
        <div class="numpad-input">{{ ret }}</div>
      </v-col>
    </v-row>

    <!-- Numpad keys -->
    <v-row no-gutters class="px-2 py-4">
      <v-col class="d-flex justify-center" cols="4" v-for="digit in buttons" :key="digit">
        <v-btn class="numpad-button" text x-large @click="onKeyPressed(digit)"
          :disabled="digit === -2 && (!ret.length || ret.length > 10)">
          <span v-if="digit >= 0">{{ digit }}</span>
          <v-icon v-else-if="digit === -1">mdi-backspace</v-icon>
          <v-icon v-else>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
export default {
  name: 'Numpad',

  mounted() {

  },

  methods: {
    onKeyPressed(digit) {
      // Normal digit (0~9)
      if (digit >= 0 && this.ret.length < 10) {
        this.ret += `${digit}`;
      }
      // Backspace (-1)
      else if (digit === -1) {
        this.ret = this.ret.slice(0, -1);
      }
      // Submit (-2)
      else {
        // this.$emit('submit', parseInt(this.ret));
        this.$emit('submit', this.ret);//FIXME: "this.ret" is a string.
      }
    },

    reset() {
      this.ret = '';
    }
  },

  data() {
    return {
      // val: 0,
      ret: '',
      buttons: [
        7, 8, 9,
        4, 5, 6,
        1, 2, 3,
        -1, 0, -2
      ]
    };
  }
}
</script>

<style scoped>
.numpad-input {
  width: 100%;
  border: 2px solid #bbbbbb;
  height: 64px;
  border-radius: 8px;
  font-size: x-large;
  text-align: end;
  padding: 0.5em 1em;
}

.numpad-button {
  font-size: xx-large;
  color: #666666;
}
</style>
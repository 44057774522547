<template>
  <div>
    <div class="outer" ref="outer"
      v-bind:class="{
        'unlocked': unlocked,
        'unlocked-left': unlocked === 'left',
        'unlocked-right': unlocked === 'right'
      }">

      <div class="lock" ref="lock" v-bind:class="{'left': unlocked === 'left', 'right': unlocked === 'right'}"
        @mousedown="dragStart" @touchstart="dragStart">
      </div>

      <div class="d-flex justify-space-around align-center" style="height: 100%;">
        <span class="headline d-flex align-center" v-if="!unlocked || unlocked === 'left'">
          <v-icon v-if="!unlocked">mdi-chevron-triple-left</v-icon>
          取消
          <v-progress-circular class="ml-2" indeterminate size="18" color="red darken-1" v-show="unlocked"></v-progress-circular>
        </span>
        <span class="headline d-flex align-center" v-if="!unlocked || unlocked === 'right'">
          <v-progress-circular class="mr-2" indeterminate size="18" color="green darken-1" v-show="unlocked"></v-progress-circular>
          接單
          <v-icon v-if="!unlocked">mdi-chevron-triple-right</v-icon>
        </span>
      </div>

    </div>

  </div>
</template>

<script>
let lockRect = null;
let dragProps = null;

export default {
  name: 'SlideButton',

  methods: {
    dragStart(evt) {
      if (this.unlocked) return;

      lockRect = this.$refs['lock'].getBoundingClientRect();
      const x = this.getX(evt);//Current mouse X.
      let startingX = (this.outerRect.width - lockRect.width) / 2;//Always start at the center.
      dragProps = {//Save some starting props.
        start: startingX,
        mouseStart: x,
        newX: startingX
      };
      this.$refs['lock'].classList.add('dragging');
      // Turn on all related listeners.
      document.addEventListener('mousemove', this.dragLock, false);
      document.addEventListener('touchmove', this.dragLock, { passive: false });
      document.addEventListener('mouseup', this.dragStop);
      document.addEventListener('touchend', this.dragStop);
    },
    dragLock(evt) {
      evt.preventDefault();
      const posX = this.getX(evt);//Moved mouse X.
      const mouseDiff = posX - dragProps.mouseStart;
      const maxX = this.outerRect.width - lockRect.width;//The rightmost limit.
      let newX = dragProps.start + mouseDiff;
      newX = this.clamp(newX, 0, maxX);//Avoid exceeding both ends.
      this.$refs['lock'].style.left = newX + 'px';
    },
    dragStop(reset) {
      this.$refs['lock'].classList.remove('dragging');

      let x = parseInt(this.$refs['lock'].style.left);
      // console.log('dragStop', x);
      // If either of both ends is hit...
      if (x <= 0)
        this.unlock('left');
      else if (x >= this.outerRect.width - lockRect.width - 1)
        this.unlock('right');
      // Otherwise, reset the lock position.
      else {
        this.$refs['lock'].style.left = `${dragProps.start}px`;
      }
      
      // Turn off all related listeners.
      document.removeEventListener('mousemove', this.dragLock, false);
      document.removeEventListener('touchmove', this.dragLock, { passive: false });
      document.removeEventListener('mouseup', this.dragStop);
      document.removeEventListener('touchend', this.dragStop);
    },

    unlock(whichEnd) {
      // this.dragStop(false);
      this.unlocked = whichEnd;
      this.$emit(`unlock-${whichEnd}`);
    },

    clamp(val, min, max) {
      return Math.min(Math.max(val, min), max);
    },
    getX(evt) {
      if (this.isTouch && evt.touches) return evt.touches[0].pageX;
      return evt.clientX;
    },

    reset() {
      this.unlocked = undefined;
      this.$refs['outer'].classList.remove('unlocked');
      this.$refs['outer'].classList.remove('unlocked-left');
      this.$refs['lock'].classList.remove('left');
      this.$refs['lock'].style.left = `${dragProps.start}px`;
    }
  },

  data() {
    return {
      unlocked: undefined
    };
  },

  computed: {
    outerRect() {
      return this.$refs['outer'].getBoundingClientRect();
    },

    isTouch() {
      return 'ontouchstart' in document.documentElement
    }
  }
}
</script>

<style scoped>
.outer {
  background: lightgray;
  border-radius: 24px;
  display: inline-block;
  width: 100%;
  height: 48px;
  position: relative;
  user-select: none;
}

.outer > .lock {
  width: 48px;
  height: 48px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 24px;
  cursor: pointer;
  position: absolute;
  left: calc(50% - 24px);
  transition: background 0.2s ease-out, left 0.1s ease-out;
}

.outer > .lock.dragging {
  transition: none;
}
.outer.unlocked {
  opacity: 0.8;
}
.outer.unlocked-left {
  background: #FFCDD2;
}
.outer.unlocked-right {
  background: #C8E6C9;
}
.outer.unlocked > .lock.left {
  background: #F44336;
  border-color: #FFCDD2;
  cursor: none;
}
.outer.unlocked > .lock.right {
  background: #4CAF50;
  border-color: #C8E6C9;
  cursor: none;
}

</style>